"use client";
import { select, hierarchy, treemap, treemapSquarify } from "d3";
import { useRef, useEffect, useState } from "react";

import styles from "./styles.module.css";
import {
  formatNumberToAccountingValue,
  formatNumberToPercentValue,
} from "@/app/utils/intl";
import useElementSize from "@/app/hooks/useElementSize";

interface ITreeMapData {
  name: string;
  label: string;
  value: number;
  percentage: number;
}

export default function TreeMapChart({ data }: { data: ITreeMapData[] }) {
  const chartRef = useRef<HTMLDivElement>(null);
  const svgRef = useRef<SVGSVGElement>(null);
  const tooltipRef = useRef<HTMLDivElement>(null);
  const { width } = useElementSize(chartRef);
  const [error, setError] = useState<string>("");

  useEffect(() => {
    // Reset
    setError("");

    // Check clause
    if (!width) return;
    if (!chartRef.current) return;
    if (!data?.length) return;

    const margin = { top: 0, right: 0, bottom: 0, left: 0 };
    const outerWidth = width;
    const outerHeight = outerWidth * 0.8;
    const innerWidth = outerWidth - margin.left - margin.right;
    const innerHeight = outerHeight - margin.top - margin.bottom;

    // Render the chart
    const svg = select(svgRef.current)
      .data([null])
      .join("svg")
      .classed("svg_chart", true)
      .attr("width", outerWidth)
      .attr("height", outerHeight)
      .attr("viewBox", [0, 0, outerWidth, outerHeight]);

    try {
      // Draw the tree map
      const root = hierarchy({
        children: data,
        name: "root",
      })
        .sum((d: any) => d.value)
        .sort((a: any, b: any) => b.value - a.value);

      const map = treemap()
        .size([innerWidth, innerHeight])
        .tile(treemapSquarify.ratio(0.8))
        .paddingTop(20)
        .paddingRight(0)
        .paddingBottom(0)
        .paddingLeft(0)
        .paddingInner(4);

      const nodes = map(root as any);
      const leaves = nodes.leaves();
      const groups = root.descendants().filter((d: any) => d.depth == 1);

      // Group
      const g = svg
        .selectAll(".svg_g")
        .data([null])
        .join("g")
        .classed("svg_g", true)
        .attr("width", innerWidth)
        .attr("height", innerHeight)
        .attr("transform", `translate(${margin.left},${margin.top})`);

      // Nodes
      g.selectAll(".g_nodes")
        .data(leaves)
        .join("rect")
        .classed("g_nodes", true)
        .attr("x", (d) => d.x0)
        .attr("y", (d) => d.y0)
        .attr("rx", 4)
        .attr("width", (d) => d.x1 - d.x0)
        .attr("height", (d) => d.y1 - d.y0)
        .style("stroke", (d: any) => d.parent.data.color)
        .style("fill", (d: any) => {
          const color = d.parent.data.color;
          return `${color}80`;
        });

      // Labels
      g.selectAll(".g_labels")
        .data(groups)
        .join("text")
        .classed("g_labels", true)
        .attr("x", (d: any) => d.x0)
        .attr("y", (d: any) => d.y0 + 2)
        .text((d: any) => {
          const text = d.data.label as string;
          return text;
        })
        .style("font-size", "16px")
        .style("font-weight", "500")
        .style("text-anchor", "start")
        .style("alignment-baseline", "hanging")
        .style("fill", "var(--medium)");

      // Names
      g.selectAll(".g_names")
        .data(leaves)
        .join("text")
        .classed("g_names", true)
        .attr("x", (d) => d.x0 + 4)
        .attr("y", (d) => {
          const w = d.x1 - d.x0;
          const h = d.y1 - d.y0;
          const s = w * 0.1 * 0.8;
          return d.y0 + h / 2 - s;
        })
        .text((d: any) => {
          const name = d.data.name as string;
          if (name === "OTHERS") return "OTHERS*";
          if (name.includes(".eth")) return name;
          return `${name.slice(0, 8)}...`;
        })
        .style("font-size", (d: any) => {
          const w = d.x1 - d.x0;
          const h = d.y1 - d.y0;
          const s = Math.min(w, h) * 0.1 * 0.8;
          return s;
        })
        .style("font-weight", "600")
        .style("text-anchor", "start")
        .style("alignment-baseline", "hanging")
        .style("fill", "var(--black)");

      // Values
      g.selectAll(".g_values")
        .data(leaves)
        .join("text")
        .classed("g_values", true)
        .attr("x", (d) => d.x0 + 4)
        .attr("y", (d) => {
          const w = d.x1 - d.x0;
          const h = d.y1 - d.y0;
          const s = Math.min(w, h) * 0.1 * 0.6;
          return d.y0 + h / 2 + s;
        })
        .text((d: any) => {
          const text = `${formatNumberToAccountingValue(d.data.value)} (${formatNumberToPercentValue(d.data.percent)})`;
          return text;
        })
        .style("font-size", (d: any) => {
          const w = d.x1 - d.x0;
          const h = d.y1 - d.y0;
          const s = Math.min(w, h) * 0.1 * 0.6;
          return s;
        })
        .style("fill", "var(--black)");

      // Create tooltip
      const tooltip = select(tooltipRef.current);

      // Overlay
      g.selectAll(".g_overlay")
        .data(leaves)
        .join("rect")
        .classed("g_overlay", true)
        .attr("x", (d) => d.x0)
        .attr("y", (d) => d.y0)
        .attr("rx", 4)
        .attr("width", (d) => d.x1 - d.x0)
        .attr("height", (d) => d.y1 - d.y0)
        .style("stroke", "transparent")
        .style("fill", "transparent")
        .style("cursor", "pointer")
        .on("mouseenter", function (event, d: any) {
          select(this)
            .style("stroke", "var(--blue)")
            .style("fill", "#141a9940");
          const addr = d?.data?.name || "";
          const value = formatNumberToAccountingValue(d?.data?.value || 0);
          const percent = formatNumberToPercentValue(d?.data?.percent || 0);
          const choice = d?.parent?.data?.label || "";

          // Add tooltip
          tooltip.style("display", "block").html(
            `<div>
                <span>Voter</span>
                <span>${addr}</span>
            </div>
            <div>
                <span>Choice</span>
                <span>${choice}</span>
            </div>
            <div>
                <span>Votes</span>
                <span>${value} (${percent})</span>
            </div>`,
          );
          const posX = event.offsetX;
          const posY = event.offsetY - 16;
          const moveX =
            posX < innerWidth * 0.2
              ? "0%"
              : posX > innerWidth * 0.8
                ? "-100%"
                : "-50%";
          const moveY = "-100%";

          // Position tooltip
          tooltip
            .style("transform", `translate(${moveX},${moveY})`)
            .style("top", `${posY}px`)
            .style("left", `${posX}px`);
        })
        .on("mousemove", function (event) {
          const posX = event.offsetX;
          const posY = event.offsetY - 16;
          const moveX =
            posX < innerWidth * 0.2
              ? "0%"
              : posX > innerWidth * 0.8
                ? "-100%"
                : "-50%";
          const moveY = "-100%";

          // Position tooltip
          tooltip
            .style("transform", `translate(${moveX},${moveY})`)
            .style("top", `${posY}px`)
            .style("left", `${posX}px`);
        })
        .on("mouseleave", function () {
          select(this)
            .style("stroke", "transparent")
            .style("fill", "transparent");

          // Remove tooltip
          tooltip
            .style("display", "none")
            .style("transform", "translate(0,0)")
            .style("top", 0)
            .style("left", 0)
            .html("");
        })
        .on("click", function (_: any, d: any) {
          const addr = d?.data?.addr || "";
          if (!addr || addr === "OTHERS") return;

          // Redirect to profile
          const href = `/profile/${addr}`;
          window.location.href = href;
        });
    } catch (error) {
      setError("Error: Invalid data. Chart cannot be rendered.");
    }
    // Clean up
    return () => {
      svg.selectAll("*").remove();
    };
  }, [data, width]);

  if (error) {
    return (
      <div className={styles.treeMapChart} ref={chartRef}>
        <div className={styles.treeMapChartError}>{error}</div>
      </div>
    );
  }

  return (
    <div className={styles.treeMapChart} ref={chartRef}>
      <svg ref={svgRef}></svg>
      <div className={styles.treemapChartTooltip} ref={tooltipRef}></div>
    </div>
  );
}
