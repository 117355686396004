import styles from "./styles.module.css";
import Asset from "@/app/components/Assets";
import WrapperChart from "@/app/components/Charts/WrapperChart";
import TreeMapChart from "@/app/components/Charts/TreeMapChart";

export default function ChartModal({
  modal,
  modalData,
  handleModal,
}: {
  modal: boolean;
  modalData: any;
  handleModal: (type: string | null, modalData: any) => void;
}) {
  const chartLink = modalData?.chartLink || "";
  const chartTitle = modalData?.chartTitle || "";
  const chartData = modalData?.chartData || [];
  const chartNotes = [
    "Shows the distribution of votes for each option in a copeland matchup.",
    "OTHERS is the sum of votes from all voters with 1% or less of the total voting power.",
  ];

  const closeModal = () => {
    handleModal(null, null);
  };

  return (
    <>
      {modal && (
        <div className={styles.chartModal}>
          <div className={styles.chartModalWrapper}>
            <div onClick={closeModal} className={styles.chartModalBtn}>
              <Asset
                type={"icon"}
                id={"close"}
                color={"var(--black)"}
                size={20}
                asAvatar={true}
              />
            </div>
            <WrapperChart linkToCopy={chartLink}>
              <div className={styles.chartModalChart}>
                <h2 className={styles.chartModalTitle}>
                  Copeland Matchup Results
                </h2>
                <h2 className={styles.chartModalTitle}>{chartTitle}</h2>
                <TreeMapChart data={chartData} />
                <ul>
                  {chartNotes.map((note: string, index: number) => (
                    <li key={index}>{note}</li>
                  ))}
                </ul>
              </div>
            </WrapperChart>
          </div>
        </div>
      )}
    </>
  );
}
