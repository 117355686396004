"use client";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { useRef, useContext } from "react";
import { saveAs } from "file-saver";
import html2canvas from "html2canvas";
import { ModalContext } from "@/app/context/modal";
import styles from "./styles.module.css";
import Asset from "@/app/components/Assets";

export default function WrapperChart({
  children,
  linkToCopy,
}: {
  children: React.ReactNode;
  linkToCopy: string;
}) {
  const canvasRef = useRef(null);
  const { handleModal } = useContext(ModalContext);

  const handleOnClick = async () => {
    if (!canvasRef?.current) return;

    const canvas = await html2canvas(canvasRef?.current, {
      onclone: (document) => {
        Array.from(document.querySelectorAll("text")).forEach(
          (element: SVGTextElement) => {
            element.style["fontFamily"] = "Verdana";
            element.style["fontWeight"] = "400";
          },
        );
      },
    });

    const data = canvas.toDataURL("image/png;base64");
    try {
      saveAs(data, "chart.png");
    } catch (error) {
      // Show error message
      handleModal("error", "Failed to download");
    }
  };

  return (
    <div className={styles.wrapperChartContainer}>
      <div className={styles.wrapperChartBtns}>
        <CopyToClipboard text={linkToCopy}>
          <button type="button" aria-label="Copy link">
            <Asset type={"icon"} id={"link"} size={22} color="var(--medium)" />
          </button>
        </CopyToClipboard>
        <span></span>
        <button
          onClick={handleOnClick}
          type="button"
          aria-label="Download chart"
        >
          <Asset
            type={"icon"}
            id={"download"}
            size={20}
            color="var(--medium)"
          />
        </button>
      </div>
      <div className={styles.wrapperChart} ref={canvasRef}>
        <div className={styles.wrapperChartLogo}>
          <Asset type={"logo"} id={"dhive"} size={44} />
          <div>dhive</div>
        </div>
        {children}
      </div>
    </div>
  );
}
