"use client";
import { useContext, useEffect } from "react";
import { ModalContext } from "@/app/context/modal";
import useWindowSize from "@/app/hooks/useWindowSize";
import styles from "./styles.module.css";
import SignInModal from "./SignInModal";
import SearchModal from "./SearchModal";
import SubscribeModal from "./SubscribeModal";
import AlertModal from "./AlertModal";
import ChartModal from "./ChartModal";

export default function Modal() {
  const { modal, modalType, modalData, handleModal } = useContext(ModalContext);
  const { width } = useWindowSize();

  useEffect(() => {
    if (modalType === "search" && width >= 768) {
      handleModal(null, null);
    }
  }, [width, modalType, handleModal]);

  if (modalType === "search" && width < 768) {
    return (
      <>
        {modal && (
          <div className={styles.searchModal}>
            <SearchModal handleModal={handleModal} />
          </div>
        )}
      </>
    );
  }

  if (modalType === "signin") {
    return (
      <>
        {modal && (
          <div className={styles.modal}>
            <div className={styles.modalContainer}>
              <SignInModal handleModal={handleModal} />
            </div>
          </div>
        )}
      </>
    );
  }

  if (modalType === "subscribe") {
    return (
      <>
        {modal && (
          <div className={styles.modal}>
            <div className={styles.modalContainer}>
              <SubscribeModal modalData={modalData} handleModal={handleModal} />
            </div>
          </div>
        )}
      </>
    );
  }

  if (
    modalType === "success" ||
    modalType === "error" ||
    modalType === "warning"
  ) {
    return (
      <AlertModal
        modal={modal}
        modalType={modalType}
        modalData={modalData}
        handleModal={handleModal}
      />
    );
  }

  if (modalType === "chart") {
    return (
      <ChartModal
        modal={modal}
        modalData={modalData}
        handleModal={handleModal}
      />
    );
  }

  return null;
}
