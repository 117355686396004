export const formatDate = (date: string, timeZone: string = "UTC"): string => {
  const dateObj = new Date(date);

  if (isNaN(dateObj.getTime())) {
    return "Invalid Date";
  }

  const dateFormatter = new Intl.DateTimeFormat("en-US", {
    year: "numeric",
    month: "long",
    day: "numeric",
    timeZone,
  });

  const timeFormatter = new Intl.DateTimeFormat("en-US", {
    hour: "numeric",
    minute: "numeric",
    hour12: true,
    timeZone,
  });

  const formattedDate = dateFormatter.format(dateObj);
  const formattedTime = timeFormatter.format(dateObj);

  return `${formattedDate}, ${formattedTime}`;
};

export const formatNumberToCompactValue = (num: number): string => {
  return new Intl.NumberFormat("en-US", {
    notation: "compact",
    compactDisplay: "short",
    maximumFractionDigits: 2,
  }).format(num);
};

export const formatNumberToPercentValue = (num: number): string => {
  return new Intl.NumberFormat("en-US", {
    style: "percent",
    minimumFractionDigits: 0,
    maximumFractionDigits: 2,
  }).format(num);
};

export const formatNumberToAccountingValue = (
  num: number,
  decimals: number = 0,
): string => {
  return new Intl.NumberFormat("en-US", {
    currencySign: "accounting",
    minimumFractionDigits: 0,
    maximumFractionDigits: decimals,
  }).format(num);
};
